import Layout from "../components/layout"
import SEO from "../components/seo"
import FeatureSuggestion from "../components/featureSuggestion"

import styles from '../styles/features.module.css'

import React from "react"
export default function Features() {
	return (
		<Layout isNavigationTransparent={true} isHomePage={false}>
			<SEO
				title="Poirot Features"
				description="Instantly find anyone's email address. Backed by industry-leading email lookup & verification technology, Poirot helps your sales and lead generation efforts."
				keywords={[
					`Poirot`,
					`Poirot Features`,
					`Email Lookup Tool`,
					`Email Finder`,
					`Email Checker`,
				]}
			/>
			<div className={styles.headerBlockWrapper}>
				<div className="sectionWrapper1200">
					<div className={styles.headerBlock}>
						<div className={styles.featuresHeaderMessage}>
							<h1>Find, verify, enrich... You name it.</h1>
							<h2>
								Here's a quick overview of the main features you'll probably be interested in as you're getting started.
							</h2>
						</div>

					</div>
				</div>
			</div>
			<div className={styles.featuresCoreWrapper}>
                <div className={'sectionWrapper1200'}>

                	<div className={`${styles.featuresCore} ${styles.featuresGrid544}`}>
                    	<div className={`${styles.featureBox} ${styles.featureBoxBlue}`}>
                			<h1>Email Address Finder</h1>
                			<h2>Search for verified corporate email addresses from your lead’s name and company name or domain. Use segmentation to classify your leads and easily export contact lists.</h2>
                		</div>
                		<div className={`${styles.featureBox} ${styles.featureBoxWhite} ${styles.featureBoxImg}`}>
                			<img src={'/images/contacts.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                		</div>
                		<div className={`${styles.featureBox} ${styles.featureBoxWhite} ${styles.featureBoxImg}`}>
                			<img src={'/images/createTags.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                		</div>
                	</div>

                	<div className={`${styles.featuresCore} ${styles.featuresGrid445}`}>
                    	<div className={`${styles.featureBox} ${styles.featureBoxWhite} ${styles.featureBox2h1v} ${styles.featureBoxImg}`}>
                			<img src={'/images/domainSearch.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                		</div>
                		<div className={`${styles.featureBox} ${styles.featureBoxYellow}`}>
                			<h1>Domain Search</h1>
                			<h2>Get all publicly available email addresses on any domain in a matter of minutes. When possible, our AI algorithm will provide you with specific email addresses related to the company on different domains. Alongside email addresses, Poirot will sometimes present you with alternative domain names to perform searches on.</h2>
                		</div>
                	</div>

                	<div className={`${styles.featuresCore} ${styles.featuresGrid544}`}>
                    	<div className={`${styles.featureBox} ${styles.featureBoxPurple}`}>
                			<h1>Email Enrichment</h1>
                			<h2>Integrated email enrichment will try to match all email addresses found by the domain search tool with the contact title and position in the company. It will also suggest other decision-makers in those companies for subsequent address lookups.</h2>
                		</div>
                		<div className={`${styles.featureBox} ${styles.featureBoxWhite} ${styles.featureBox2h1v} ${styles.featureBoxImg}`}>
                			<img src={'/images/enrichment.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                		</div>
                	</div>

                	<div className={`${styles.featuresCore} ${styles.featuresGrid445}`}>
                    	<div className={`${styles.featureBox} ${styles.featureBoxWhite} ${styles.featureBoxImg} ${styles.featureBox2h1v}`}>
                			<img src={'/images/verification.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                		</div>
                		<div className={`${styles.featureBox} ${styles.featureBoxGreen}`}>
                			<h1>Email Verification</h1>
                			<h2>Make sure your emails will reach your prospect's inbox. Improve deliverability and reduce bounce by verifying email address exists in real-time.</h2>
                		</div>
                	</div>

            	</div>
        	</div>

        	<div className={styles.featuresAdditionalsWrapper}>
                <div className={'sectionWrapper1200'}>
                	<div>
                		<div>
	                		<h1>Segmentation</h1>
	                		<h2>Use up to six different tags on each email address to create different lists for your mailing campaigns.</h2>
                		</div>
                		<div>
                			<img src={'/images/tags.svg'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
            			</div>
            		</div>
            		<div>
            			<div>
	                		<h1>CSV Export</h1>
	                		<h2>Export lists of segmented leads containing enriched contact data for further processing.</h2>
                		</div>
                		<div>
                			<img src={'/images/csvExport.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
            			</div>
            		</div>
            		<div>
            			<div>
		            		<h1>Bulk Actions</h1>
	                		<h2>Perform email lookups, domain searches, and email verifications on a scale. You may import files with up to 10.000 lines of lookup/verification data and receive results when the task action is completed.</h2>
                            <span>Available - Late June 2021</span>
                		</div>
                		<div>
                			<img src={'/images/bulk.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
            			</div>
            		</div>
                </div>
            </div>

            <div className={styles.featuresOtherWrapper}>
                <div className={'sectionWrapper980'}>
                	<div>
                		<h1>Other Features & Enhancements</h1>
                		<hr />
                		<div className={styles.otherFeatures}>
                			<h2>Teamwork</h2>
                			<p>Unlimited teams. Unlimited team members. Organize and delegate your search campaigns to specific teams and control their performance and credit usage. All your team members share common monthly quotas provided with the plan. Change team workspaces to access separate lookup and verification tasks.</p>
                			<h2>API Access</h2>
                			<p>Connect Poirot with any 3rd party software application to automate the email lookup and verification process and integrate it into your workflow. Easily receive Poirot results using webhooks or plain HTTP requests.</p>
                			<h2>Only Verified Emails Count</h2>
                			<p>We are charging only for emails we are sure won't bounce and will reach the receiver's inbox.</p>
                			<h2>Affiliates</h2>
                			<p>As a Poirot affiliate, you’ll earn a 30% commission each month from everyone you refer. That means if you send us an account worth $99/month, you’ll earn $30. Every month. Besides that, you and everyone you refer will receive 25 permanent credits each after the signup. There is no limit to the amount of credit or recurring revenue you can earn through referrals.</p>
                			<h2>Permanent Credits Option</h2>
                			<p>Purchase permanent credits and stop worrying they will expire at the end of the billable period.</p>
                			<h2>Security</h2>
                			<p>Secure your account searches and credits using a two-factor authentication process to add an extra layer of security. To log in, you'll need to provide a code along with your username and password.</p>
                			<h2>Competitive Pricing</h2>
                			<p>We charge credits only for successful lookup tasks. When a domain search is used, we charge a single credit for each block of up to 10 verified email addresses returned. If for example, 19 email addresses are found, only 2 credits will be used. 22 email addresses on the other hand will cost you only 3 credits. Failed requests and requests with no result are not charged. For email verifications, each request is charged with single verification credit.</p>
                			<h2>Refund Policy</h2>
                			<p>We’d hate to see you go, but we totally understand that sometimes things just don’t work out. We have a 30-day refund policy, so if you cancel, you can request any funds paid in the last 30 days to be refunded in full. Our 30-day refund policy applies to all plans, including annual plans. We do not refund or pro-rate payments made more than 30 days prior to the request.</p>
                		</div>
                	</div>
                </div>
            </div>

		<FeatureSuggestion />
		</Layout>
	)
}