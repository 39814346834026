import React, { Component } from "react"
import axios from "axios"

import styles from "../styles/featureSuggestion.module.css"

let settings = require("../settings")

class FeatureSuggestion extends Component {
	constructor(props) {
		super(props)

		this.state=({
			feature: "",
			honeyPot: ""
		})

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleMessageSend = this.handleMessageSend.bind(this)
	}

	handleInputChange(event) {

        const target = event.target;
        var value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleMessageSend(event) {

    	event.preventDefault();

    	const request = {
            "feature": this.state.feature,
        };

        if (this.state.honeyPot !== "") {
        	return
        }

        if (this.state.feature === "") {
            return
        }

        var self = this;

        axios.get(settings.SUGGEST_FEATURE_WEBHOOK, { params: request })
            .then(function (response) {
            	console.log(response)
                if (response.status === 200) {
                    self.setState({
                    	feature: "",
                    	honeyPot: ""
                    })
	            } else {
	            	self.setState({
                    	feature: "",
                    	honeyPot: ""
                    })
	            }
        })
            .catch(function (error) {
            	self.setState({
                    	feature: "",
                    	honeyPot: ""
                    })
                return
        });
    }

	render() {
		return (
			<div className={styles.featureSuggestionBlockWrapper}>
				<div className="sectionWrapper1200">
					<div className={styles.featureSuggestionBlock}>
						<div className={styles.featureSuggestionMessage}>
							<h1>Have an idea for a new feature or an improvement?</h1>
							<h2>We are always interested to find out how we can improve Poirot. We want to hear about those small tweaks that will make a difference for your workflow.</h2>
						</div>
						<div className={styles.featureSuggestionFormWrapper}>
							<form className={styles.featureSuggestionForm}>
								<div>
									<textarea name="feature" type="text" placeholder="Feature suggestion..." value={this.state.feature} onChange={this.handleInputChange}/>
								</div>
								<div>
									<input className={styles.honeyPot} type="text" name="honeyPot" value={this.state.honeyPot} onChange={this.handleInputChange}/>
									<button className="defaultButtonBlue" onClick={(event) => this.handleMessageSend(event)}>
										Make a Suggestion
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default FeatureSuggestion